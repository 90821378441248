import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { UserSessionProvider } from 'src/contexts/UserSessionContext';
import { UtilsProvider } from './contexts/UtilsContext';

ReactDOM.render(
  <HelmetProvider>
    <UserSessionProvider>
      <SidebarProvider>
        <UtilsProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </UtilsProvider>
      </SidebarProvider>
    </UserSessionProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
