export enum Arm {
    LEFT = 'left',
    RIGHT = 'right'
}

export enum GenderCategory {
    MEN = 'men',
    WOMEN = 'women'
}

export enum WeightCategory {
    MEN_SHW = 'men_1000',
    MEN_HW = 'men_115',
    MEN_LHW = 'men_105',
    MEN_MW = 'men_95',
    MEN_WW = 'men_85',
    MEN_LW = 'men_75',
    WOMEN_LW = 'women_65',
    WOMEN_MW = 'women_80',
    WOMEN_SHW = 'women_1000',
}

export enum RankingType {
    TIER_LIST_RANKING = 'tierListRanking',
    POINT_BASED_RANKING = 'pointBasedRanking',
    NERD_RANKING = 'nerdRanking'
}

export enum NodeSize {
    XSMALL = 'xtra-small',
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large'
}

export enum OptionsHttpMethods {
    POST = 'post',
    PUT = 'put',
    GET = 'get',
    DELETE = 'delete',
    PATCH = 'patch'
}

export enum ScrollDirection {
    UP = "up",
    DOWN = "down"
}