import GroupsIcon from '@mui/icons-material/Groups';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import CompareIcon from '@mui/icons-material/Compare';

export const NAV_MENU = [
    {
        label: "Pullers",
        route: "/armwrestlers",
        icon: GroupsIcon
    },
    {
        label: "Events",
        route: "/events",
        icon: EmojiEventsIcon
    },
    {
        label: "Stats",
        route: "/stats",
        icon: QueryStatsIcon
    },
    {
        label: "Compare",
        route: "/compare",
        icon: CompareIcon
    }
]