
export class UserSession implements User {
  email: "guest@nerds.com";
  username: "Guest";
}

export interface User {
  email: string;
  username: string;
}
