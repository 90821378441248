import { Navigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserSessionContext } from './contexts/UserSessionContext';

interface Props {
  children: JSX.Element;
}

function Authentication({
  children
}: Props) {
  const { isAuthenticated } = useContext(UserSessionContext)

  if (isAuthenticated) {
    return children
  } else {
    return <Navigate to={"/stats"} />
  }
}
export default Authentication;
