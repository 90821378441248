import { Box, useTheme, BottomNavigation, BottomNavigationAction, Slide } from "@mui/material";
import { createElement } from "react";
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { useScrollDirection } from "src/hooks/useScrollbar";
import { ScrollDirection } from "src/models/optionsValues";
import { NAV_MENU } from "src/layouts/navMenu";


const FooterNav = () => {
    const theme = useTheme();
    const path = useLocation();
    const scrollDir = useScrollDirection();

    return (
        <Slide direction="up" in={scrollDir === ScrollDirection.UP} mountOnEnter unmountOnExit>
                <Box
                    sx={{
                        width: "100%",
                        position: "fixed",
                        bottom: 0,
                        zIndex: 1200,
                        backgroundColor: theme.palette.background.default,
                        borderTop: `1px solid ${theme.palette.grey[900]}`
                    }}
                >
                        <BottomNavigation
                            showLabels
                            sx={{
                                maxHeight: "50px",
                                backgroundColor: theme.palette.background.default
                            }}
                            value={path.pathname}
                        >
                                { NAV_MENU.map((item) => 
                                    <BottomNavigationAction
                                        key={item.label}
                                        component={RouterLink}
                                        value={item.route}
                                        to={item.route}
                                        label={item.label}
                                        icon={createElement(item.icon)}
                                    />
                                )}
                        </BottomNavigation>
                </Box>
        </Slide>
    )
}
export default FooterNav