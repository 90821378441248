import { useMediaQuery, useTheme } from '@mui/material';
import { FC, createContext, useMemo } from 'react';

type UtilsContextType = {
  screenHeight: number;
  contentHeightLg: number;
  contentHeightMd: number;
  contentHeightSm: number;
  avatarHeightLg: number;
  avatarHeightMd: number;
  avatarHeightSm: number;
  contentWidthtLg: number;
  contentWidthtMd: number;
  contentWidthtSm: number;
};

export const UtilsContext = createContext<UtilsContextType>(
  {} as UtilsContextType
);

export const UtilsProvider: FC = ({ children }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const screenHeight = window.innerHeight;
  const contentHeightLg = mobile ? window.innerHeight * 0.66 : 400
  const contentHeightMd = mobile ? window.innerHeight * 0.50 : 350;
  const contentHeightSm = mobile ? window.innerHeight * 0.33 : 300;
  const avatarHeightLg = mobile ? 50 : 70;
  const avatarHeightMd = mobile ? 30 : 50;
  const avatarHeightSm = mobile ? 10 : 30;

  const contentWidthtLg = mobile ? window.innerWidth : 500
  const contentWidthtMd = mobile ? window.innerWidth : 400;
  const contentWidthtSm = mobile ? window.innerWidth : 300;

  return (
    <UtilsContext.Provider
      value={{
        screenHeight,
        contentHeightLg,
        contentHeightMd,
        contentHeightSm,
        avatarHeightLg,
        avatarHeightMd,
        avatarHeightSm,
        contentWidthtLg,
        contentWidthtMd,
        contentWidthtSm
      }}
    >
      {children}
    </UtilsContext.Provider>
  );
};
