import { useTheme, useMediaQuery, Box, ToggleButton, FormControlLabel, Switch, Stack, ToggleButtonGroup, Theme, IconButton } from '@mui/material';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import RedditIcon from '@mui/icons-material/Reddit';

function SocialMedia() {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Stack
            direction="row"
            spacing={2}
            sx={{ 
                // position: "absolute",
                // width: "100px",
                right: 0,
                // left: 80,
                zIndex: 1000
            }}>
            <IconButton onClick={() =>window.open('https://facebook.com/Aw4Nerds','_blank')}>
                <FacebookIcon color="secondary" />
            </IconButton>
            <IconButton color="secondary" onClick={() =>window.open('https://instagram.com/Aw4Nerds','_blank')}>
                <InstagramIcon />
            </IconButton>
            <IconButton color="secondary" onClick={() =>window.open('https://www.youtube.com/@Aw4Nerds','_blank')}>
                <YouTubeIcon />
            </IconButton>
            <IconButton color="secondary" onClick={() =>window.open('https://reddit.com/user/AW_4_Nerds','_blank')}>
                <RedditIcon />
            </IconButton>
        </Stack>
    );
}

export default SocialMedia;
