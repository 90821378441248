import { FC, useState, createContext, useEffect } from 'react';
import useApiService from 'src/hooks/useApiService';
import { OptionsHttpMethods } from 'src/models/optionsValues';
import { User, UserSession } from 'src/models/user';
type UserSessionContext = {
  isAuthenticated: boolean;
  user: User;
  handleLogin: () => void;
  handleLogout: () => void;
};

export const UserSessionContext = createContext<UserSessionContext>(
  {} as UserSessionContext
);

export const UserSessionProvider: FC = ({ children }) => {
  const { httpRequest } = useApiService();
  const [user, setUser] = useState<User>(new UserSession);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
 
  useEffect(() => {
    // getCurrentUser();
  }, []);

  const getCurrentUser = () => {
    const address = `${window.ARM_LIBRARY_ADDRESS}/users/current`;
    httpRequest(OptionsHttpMethods.GET, address).then((resp: User) => {
        setUser(resp);
        setIsAuthenticated(true);
    })
    .catch((error) => {
        console.log(error);
        setIsAuthenticated(false);
    })
  }

  const handleLogin = () => {
      const address = `${window.ARM_LIBRARY_ADDRESS}/users/login`;
      const body = {}
      httpRequest(OptionsHttpMethods.POST, address, body).then((resp: User) => {
          setUser(resp);
          setIsAuthenticated(true);
      })
      .catch((error) => {
          console.log(error);
      })
  }

  const handleLogout = () => {
      const address = `${window.ARM_LIBRARY_ADDRESS}/users/logout`;

      httpRequest(OptionsHttpMethods.POST, address, {}).then((resp: User) => {
          setUser(new UserSession());
          setIsAuthenticated(false);
      })
      .catch((error) => {
          console.log(error);
      })
  }

  return (
    <UserSessionContext.Provider
      value={{ handleLogin, handleLogout, user, isAuthenticated }}
    >
      {children}
    </UserSessionContext.Provider>
  );
};
